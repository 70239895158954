body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    
}

.pos-item{
  cursor: pointer;
  font-weight: bold;
}

.pos-item:hover{
background:  rgb(118, 153, 186);
color: rgb(255, 255, 255);
font-style: italic;
}


.pos-item img{
  width:30%;
  /*max-height: 20px;*/
  object-fit: cover;
}

.h6{
  font-size: small;
}





